<template>
  <div>
    <div class="top">
      <a-form-model :model="form" layout="inline">
        <a-form-model-item>
          <a-select
            v-model="form.version"
            style="width: 200px"
            placeholder="选择版本号"
          >
            <a-select-option
              v-for="item in objData.versions"
              :value="item.value"
              :key="item.value"
              >{{ item.version }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="form.version1"
            style="width: 200px"
            placeholder="选择版本号"
            :autoFocus=true
          >
            <a-select-option
              v-for="item in objData.versions"
              :value="item.value"
              :key="item.value"
              >{{ item.version }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            type="primary"
            @click="compareSure"
            :disabled="!form.version || !form.version1"
            >对比</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-row :gutter="26">
      <a-col :span="12">
        <div class="version">
          <h3>{{ detailLeft.version }}</h3>
          <p>
            <span>物料名称：{{ detailLeft.name }}</span>
          </p>
          <p>
            <span>分类：{{ detailLeft.classificationName }}</span>
          </p>
          <p>
            <span>规格型号：{{ detailLeft.specification }}</span>
          </p>
          <p>
            <span>主工艺：{{ detailLeft.mainProcess }}</span>
          </p>
          <p>
            <span>材质：{{ detailLeft.texture }}</span>
          </p>
          <p>
            <span>单位：{{ detailLeft.unitName }}</span>
          </p>
          <p>
            <span>备注：{{ detailLeft.description }}</span>
          </p>
        </div>
        <a-table
          bordered
          :expanded-row-keys.sync="expandedRowKeys"
          childrenColumnName="childBom"
          :columns="columns"
          :data-source="tableLeft"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
        </a-table>
      </a-col>
      <a-col :span="12">
        <div class="version">
          <h3>{{ detailRight.version }}</h3>
          <p>
            <span>物料名称：{{ detailRight.name }}</span>
          </p>
          <p>
            <span>分类：{{ detailRight.classificationName }}</span>
          </p>
          <p>
            <span>规格型号：{{ detailRight.specification }}</span>
          </p>
          <p>
            <span>主工艺：{{ detailRight.mainProcess }}</span>
          </p>
          <p>
            <span>材质：{{ detailRight.texture }}</span>
          </p>
          <p>
            <span>单位：{{ detailRight.unitName }}</span>
          </p>
          <p>
            <span>备注：{{ detailRight.description }}</span>
          </p>
        </div>
        <a-table
          bordered
          :expanded-row-keys.sync="expandedRowKeys1"
          childrenColumnName="childBom"
          :columns="columns"
          :data-source="tableRight"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { getChildBom, getVersionList } from "@/api/bom.js";

export default {
  components: {},
  props: {
    objData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: [],

      columns: [
        {
          title: "物料编码",
          dataIndex: "materialCode",
          ellipsis: true,
        },
        {
          title: "物料名称",
          dataIndex: "materialName",
          ellipsis: true,
        },
        {
          title: "规格型号",
          dataIndex: "specification",
          ellipsis: true,
        },
        {
          title: "版本号",
          dataIndex: "versionText",
          ellipsis: true,
        },
      ],

      form: {
        version: undefined,
        version1: undefined,
      },

      detailLeft: {},
      detailRight: {},

      tableLeft: [],
      tableRight: [],

      expandedRowKeys: [],
      expandedRowKeys1: [],
    };
  },
  created() {
    console.log("objData", this.objData);
    this.form.version = this.objData.version;
  },
  methods: {
    compareSure() {
      // 对比
      console.log("compareSure", this.form.version, this.form.version1);
      let id = "";
      let id1 = "";
      this.objData.versions.forEach((item) => {
        if (item.value == this.form.version) {
          id = item.materialItemId;
        }
        if (item.value == this.form.version1) {
          id1 = item.materialItemId;
        }
      });

      this.getVersionList(id, id1);

      this.getChildBom(id, id1);
    },
    getVersionList(id, id1) {
      this.expandedRowKeys = [];
      this.expandedRowKeys1 = [];
      
      let params = {
        ids: [id, id1],
      };
      getVersionList(params).then((res) => {
        console.log("detail", res);
        this.detailLeft = res[0] || {};
        this.detailRight = res[1] || {};
      });
    },
    getChildBom(id, id1) {
      let params = {
        materialId: id,
      };
      getChildBom(params).then((res) => {
        console.log("tableLeft", res);

        this.tableLeft = res;
        this.expandedRowKeys.push(res[0].id);
      });

      let params1 = {
        materialId: id1,
      };
      getChildBom(params1).then((res) => {
        console.log("tableRight", res);

        this.tableRight = res;
        this.expandedRowKeys1.push(res[0].id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-bottom: 16px;
}

.version {
  margin-top: 20px;
}

.version h3 {
  text-align: center;
  margin-bottom: 20px;
}

.version p {
  margin-bottom: 15px;
}
</style>
