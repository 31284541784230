var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-input-search',{staticStyle:{"width":"360px","margin-bottom":"16px"},attrs:{"placeholder":"物料编码、物料名称、规格型号"},on:{"search":_vm.onSearch}}),_c('a-transfer',{attrs:{"data-source":_vm.dataSubmarterial,"target-keys":_vm.targetKeys,"rowKey":function (record) { return record.id; },"show-search":_vm.showSearch,"filter-option":function (inputValue, item) { return item.materialName.indexOf(inputValue) !== -1 || item.materialCode.indexOf(inputValue) !== -1 || item.specification.indexOf(inputValue) !== -1; },"show-select-all":false,"titles":['可选择物料', '已选择物料'],"locale":{searchPlaceholder: '请输入物料编码、物料名称、规格型号'}},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
      var ref_props = ref.props;
      var direction = ref_props.direction;
      var filteredItems = ref_props.filteredItems;
      var selectedKeys = ref_props.selectedKeys;
      var ref_on = ref.on;
      var itemSelectAll = ref_on.itemSelectAll;
      var itemSelect = ref_on.itemSelect;
return [_c('a-table',{attrs:{"row-selection":_vm.getRowSelection({
            selectedKeys: selectedKeys,
            itemSelectAll: itemSelectAll,
            itemSelect: itemSelect,
          }),"columns":direction === 'left' ? _vm.leftColumns : _vm.rightColumns,"data-source":filteredItems,"rowKey":function (record) { return record.id; },"size":"small","custom-row":function (ref) {
                var id = ref.id;

                return ({
            on: {
              click: function () {
                itemSelect(id, !selectedKeys.includes(id));
              },
            },
          });
      }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }