<template>
  <div>
    <a-table
      bordered
      :columns="columns"
      :data-source="dataSource"
      :rowKey="(record) => record.id"
    >
      <template slot="version" slot-scope="text, record">
        <editableselect-cell
          :text="text"
          :tlevel="record.tlevel"
          :versions="record.versions"
          @change="onCellChange(record.id, 'version', $event)"
          @editVersion="onVersion"
        />
      </template>
      <template slot="quantity" slot-scope="text, record">
        <editable-cell
          :text="text"
          @change="onCellChange(record.id, 'quantity', $event)"
        />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="onOperClick(record, 1)">
              删除
            </a-menu-item>
            <a-menu-item key="8" @click="onOperClick(record, 2)">
              历史版本
            </a-menu-item>
          </a-menu>
          <a> 操作 <a-icon type="down" /> </a>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      v-model="visibleCompareModal"
      title="历史版本比较"
      :maskClosable="false"
      :width="1300"
      :destroyOnClose="true"
      :footer="null"
    >
      <compare-version :objData="objHistory" />
    </a-modal>
  </div>
</template>
<script>
import CompareVersion from "./CompareVersion.vue";
import EditableCell from "./EditableCell.vue";
import EditableselectCell from "./EditableselectCell.vue";
import {
  getReplaces,
  deleteBOM,
  update,
  switchReplaceVersion,
} from "@/api/bom.js";

export default {
  components: {
    EditableCell,
    EditableselectCell,
    CompareVersion,
  },
  props: {
    id: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      dataSource: [],

      columns: [
        {
          title: "物料编码",
          dataIndex: "materialCode",
        },
        {
          title: "物料名称",
          dataIndex: "materialName",
        },
        {
          title: "规格型号",
          dataIndex: "specification",
        },
        {
          title: "版本号",
          dataIndex: "version",
          scopedSlots: { customRender: "version" },
        },
        {
          title: "工艺",
          dataIndex: "mainProcess",
        },
        {
          title: "材质",
          dataIndex: "texture",
        },
        {
          title: "分类",
          dataIndex: "materialClass",
        },
        {
          title: "用量",
          dataIndex: "quantity",
          scopedSlots: { customRender: "quantity" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],

      objHistory: {},
      visibleCompareModal: false,

      currentVersions: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onVersion(versions) {
      this.currentVersions = versions;
      console.log("onVersion", this.currentVersions);
    },
    getData() {
      let params = {
        bomId: this.id,
      };
      getReplaces(params).then((res) => {
        console.log("getData", res);

        res.forEach((item) => {
          item.tlevel = 1;
        });
        this.dataSource = res;
      });
    },
    onCellChange(id, dataIndex, value) {
      console.log("---", id, dataIndex, value);
      if (dataIndex == "quantity") {
        let params = {
          BomId: id,
          IsReplace: true,
          Quantity: value,
        };

        update(params).then((res) => {
          console.log("update", res);
          if (res) {
            this.getData();
          }
        });
      } else if (dataIndex == "version") {
        let materialId = "";
        this.currentVersions.forEach((item) => {
          if (item.value == value) {
            materialId = item.materialItemId;
          }
        });

        let params = {
          id,
          materialId,
        };
        console.log("version", params);
        switchReplaceVersion(params).then((res) => {
          console.log("switchReplaceVersion", res);
          if (res) {
            this.getData();
          }
        });
      }
    },
    onOperClick(record, key) {
      console.log("key", key, record);
      let _this = this;
      let id = record.id;
      let params = {
        isReplace: true,
      };

      if (key == 1) {
        // 删除
        this.$confirm({
          title: "确认删除" + record.materialName + "?",
          onOk() {
            deleteBOM(id, params).then((res) => {
              console.log("deleteBom", res);
              _this.$message.success("删除成功");

              // 更新弹窗列表
              _this.getData();

              // 更新列表
              _this.$emit("refresh");
            });
          },
        });
      } else if (key == 2) {
        // 历史版本对比
        this.visibleCompareModal = true;

        this.objHistory = record;
      }
    },
  },
};
</script>
