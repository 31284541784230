<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select
        default-value="2"
        :value="value"
        style="width: 120px"
        @change="handleChange"
      >
        <a-select-option
          v-for="item in versions"
          :value="item.value"
          :key="item.value"
          >{{ item.version }}</a-select-option
        >
      </a-select>
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ filterVersion(value) }}
      <a-icon
        v-if="(tlevel == 1 || tlevel == 2) && !selectDisabled"
        type="edit"
        class="editable-cell-icon"
        @click="edit"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EditableselectCell",
  props: {
    text: {
      type: Number,
      default: () => undefined,
    },
    versions: {
      type: Array,
      default: () => [],
    },
    tlevel: {
      type: Number,
      default: () => 0,
    },
    selectDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.text,
      editable: false,
      versionObj: {},
    };
  },
  created() {
    this.versions.forEach((item) => {
      this.versionObj[item.value] = item.version;
    });
  },
  methods: {
    handleChange(e) {
      console.log("handleChange-sel", e);
      const value = e;
      this.value = value;

      this.changeSel(value);
    },
    changeSel(value) {
      this.versions.forEach((item) => {
        if(item.value == value) {
          this.$emit("changeSel", item);
        }
      });   
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
      this.$emit("editVersion", this.versions);
    },
    filterVersion(val) {
      return this.versionObj[val] || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
  color: #1890ff;
}

.editable-cell-icon {
  line-height: 26px;
  // display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
