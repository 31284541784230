<template>
  <div>
    <a-input-search
      placeholder="物料编码、物料名称、规格型号"
      style="width: 360px; margin-bottom: 16px"
      @search="onSearch"
    />
    <a-transfer
      :data-source="dataSubmarterial"
      :target-keys="targetKeys"
      :rowKey="(record) => record.id"
      :show-search="showSearch"
      :filter-option="
        (inputValue, item) => item.materialName.indexOf(inputValue) !== -1 || item.materialCode.indexOf(inputValue) !== -1 || item.specification.indexOf(inputValue) !== -1
      "
      :show-select-all="false"
      :titles="['可选择物料', '已选择物料']"
      :locale="{searchPlaceholder: '请输入物料编码、物料名称、规格型号'}"
      @change="onChange"
    >
      <template
        slot="children"
        slot-scope="{
          props: { direction, filteredItems, selectedKeys },
          on: { itemSelectAll, itemSelect },
        }"
      >
        <a-table
          :row-selection="
            getRowSelection({
              selectedKeys,
              itemSelectAll,
              itemSelect,
            })
          "
          :columns="direction === 'left' ? leftColumns : rightColumns"
          :data-source="filteredItems"
          :rowKey="(record) => record.id"
          size="small"
          :custom-row="
            ({ id }) => ({
              on: {
                click: () => {
                  itemSelect(id, !selectedKeys.includes(id));
                },
              },
            })
          "
        />
      </template>
    </a-transfer>
  </div>
</template>

<script>
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import { getTinyList } from "@/api/bom.js";

const leftTableColumns = [
  {
    dataIndex: "materialCode",
    title: "编码",
  },
  {
    dataIndex: "materialName",
    title: "物料名称",
  },
  {
    dataIndex: "specification",
    title: "规格型号",
  },
];
const rightTableColumns = [
  {
    dataIndex: "materialCode",
    title: "编码",
  },
  {
    dataIndex: "materialName",
    title: "物料名称",
  },
  {
    dataIndex: "specification",
    title: "规格型号",
  },
];

export default {
  data() {
    return {
      dataSubmarterial: [],
      targetKeys: [],
      targetObjs: [],
      showSearch: true,
      leftColumns: leftTableColumns,
      rightColumns: rightTableColumns,
    };
  },
  mounted() {
    this.getData("");
  },
  methods: {
    onSearch(value) {
      console.log(value);

      // this.targetKeys = [];
      this.getData(value);
    },
    getData(filter) {
      let params = {
        filter,
      };

      getTinyList(params).then((res) => {
        console.log("getTinyList", res);
        console.log("this.targetObjs", this.targetObjs)

        if(this.targetKeys.length) {
          res = differenceBy(res, this.targetObjs, 'id');
          res = res.concat(this.targetObjs);
        }      
        console.log('this.dataSubmarterial', res)
        this.dataSubmarterial = res;
      });
    },
    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;

      this.getTargetObjs();
    },
    getTargetObjs() {
      this.targetObjs = [];
      this.dataSubmarterial.forEach(item => {
        this.targetKeys.forEach(val => {
          if(val == item.id) {
            this.targetObjs.push(item);
          }
        })
      })
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: disabled || item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ id }) => id);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ id }, selected) {
          itemSelect(id, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
  },
};
</script>
