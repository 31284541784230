<template>
  <div class="wrapper">
    <a-row type="flex" :gutter="16">
      <a-col flex="260px" class="left">
        <a-input-search
          placeholder="搜索物料名称"
          style="width: 226px"
          @search="onSearch"
          :allowClear="true"
        />
        <a-menu mode="vertical" v-model="currentMenu">
          <a-menu-item
            v-for="item in menus"
            @click="menuClick(item.materialId, item.isFromMaterial)"
            :key="item.materialId"
          >
            <a-icon type="inbox" />{{ item.catagoryName }}
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col flex="1" class="con">
        <div>
          <a-table
            bordered
            :rowKey="(record) => record.id"
            :data-source="dataSource"
            :columns="columns"
            :expanded-row-keys.sync="expandedRowKeys"
            childrenColumnName="childBom"
            :pagination="false"
            @expand="onExpand"
          >
            <template slot="version" slot-scope="text, record">
              <editableselect-cell
                :text="text"
                :versions="record.versions"
                :tlevel="record.tlevel"
                :selectDisabled="record.selectDisabled"
                @change="onCellChange(record, 'version', $event)"
                @changeSel="onChangeSel(record, $event)"
              />
            </template>
            <template slot="quantity" slot-scope="text, record">
              <editable-cell
                v-if="record.tlevel == 2"
                :text="text"
                @change="onCellChange(record, 'quantity', $event)"
              />
              <div v-else-if="record.tlevel != 1">
                {{ text }}
              </div>
            </template>
            <template slot="replaceNum" slot-scope="text, record">
              <div v-if="record.tlevel == 2">
                <a @click="replacematerialClick(record)">{{ text }}</a>
              </div>
            </template>
            <template slot="operation" slot-scope="text, record">
              <template v-if="record.tlevel == 1">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="1" @click="onOperClick(record, 1)">
                      添加子物料
                    </a-menu-item>
                    <a-menu-item key="6" @click="onOperClick(record, 6)">
                      编辑
                    </a-menu-item>
                    <a-menu-item
                      key="7"
                      @click="onOperClick(record, 7)"
                      v-if="!record.isFromMaterial"
                    >
                      删除
                    </a-menu-item>
                    <a-menu-item
                      key="8"
                      @click="onOperClick(record, 8)"
                      v-if="!record.isFromMaterial"
                    >
                      历史版本
                    </a-menu-item>
                  </a-menu>
                  <a> 操作 <a-icon type="down" /> </a>
                </a-dropdown>
              </template>
              <template v-if="record.tlevel == 2">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="2" @click="onOperClick(record, 2)">
                      上方添加物料
                    </a-menu-item>
                    <a-menu-item key="3" @click="onOperClick(record, 3)">
                      下方添加物料
                    </a-menu-item>
                    <a-menu-item key="4" @click="onOperClick(record, 4)">
                      上移
                    </a-menu-item>
                    <a-menu-item key="5" @click="onOperClick(record, 5)">
                      下移
                    </a-menu-item>
                    <a-menu-item key="6" @click="onOperClick(record, 6)">
                      编辑
                    </a-menu-item>
                    <a-menu-item key="7" @click="onOperClick(record, 7)">
                      删除
                    </a-menu-item>
                    <a-menu-item key="8" @click="onOperClick(record, 8)">
                      历史版本
                    </a-menu-item>
                  </a-menu>
                  <a> 操作 <a-icon type="down" /> </a>
                </a-dropdown>
              </template>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>

    <a-modal
      v-model="visibleSubmaterialModal"
      title="添加子物料"
      :maskClosable="false"
      :width="1000"
      :destroyOnClose="true"
      @ok="AddSubmaterial"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <submaterial-modal ref="refSubmaterial" />
    </a-modal>

    <a-modal
      v-model="visibleSubmateriaUpOrDownlModal"
      :title="UpOrDown == 'up' ? '上方添加物料' : '下方添加物料'"
      :maskClosable="false"
      :width="1000"
      :destroyOnClose="true"
      @ok="AddSubmaterialUpOrDown"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <submaterial-modal ref="refSubmaterialUpOrDown" />
    </a-modal>

    <a-modal
      v-model="visibleReplacematerialModal"
      title="替代物料"
      :maskClosable="false"
      :width="1000"
      :destroyOnClose="true"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <div style="text-align: right; margin-bottom: 12px">
        <a-button type="primary" @click="AddReplaceOpen">添加替代料</a-button>
      </div>

      <replacematerial-modal
        ref="refReplaceMaterial"
        :id="bomId"
        @refresh="refreshList"
      />
    </a-modal>
    <a-modal
      v-model="visibleReplacematerialChooseModal"
      title="添加替代物料"
      :maskClosable="false"
      :width="1000"
      :destroyOnClose="true"
      @ok="AddReplacematerial"
      :cancel-button-props="{ style: { display: 'none' } }"
    >
      <submaterial-modal ref="refReplaceSubmaterial" />
    </a-modal>

    <a-modal
      v-model="visibleCompareModal"
      title="历史版本比较"
      :maskClosable="false"
      :width="1300"
      :destroyOnClose="true"
      :footer="null"
    >
      <compare-version :objData="objHistory" />
    </a-modal>
  </div>
</template>

<script>
import EditableCell from "./components/EditableCell.vue";
import EditableselectCell from "./components/EditableselectCell.vue";
import SubmaterialModal from "./components/SubmaterialModal.vue";
import ReplacematerialModal from "./components/ReplacematerialModal.vue";
import CompareVersion from "./components/CompareVersion.vue";
import {
  getcatagory,
  getList,
  create,
  addReplaceBom,
  insertbom,
  movebom,
  deleteBOM,
  update,
  swichVersion,
} from "@/api/bom.js";
import { getVersionDownListDown } from "@/api/materialclassification.js";

export default {
  components: {
    EditableCell,
    EditableselectCell,
    SubmaterialModal,
    ReplacematerialModal,
    CompareVersion,
  },
  data() {
    return {
      menus: [],
      currentMenu: [],

      expandedRowKeys: [],
      dataSource: [],
      columns: [
        {
          title: "物料编码",
          dataIndex: "materialCode",
        },
        {
          title: "物料名称",
          dataIndex: "materialName",
        },
        {
          title: "版本号",
          dataIndex: "version",
          scopedSlots: { customRender: "version" },
        },
        {
          title: "规格型号",
          dataIndex: "specification",
        },
        {
          title: "分类",
          dataIndex: "materialClass",
        },
        {
          title: "材质",
          dataIndex: "texture",
        },
        {
          title: "工艺",
          dataIndex: "mainProcess",
        },
        {
          title: "用量",
          dataIndex: "quantity",
          scopedSlots: { customRender: "quantity" },
        },
        {
          title: "替代料",
          dataIndex: "replaceNum",
          scopedSlots: { customRender: "replaceNum" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],

      // 添加子物料
      visibleSubmaterialModal: false,
      // 替代物料
      visibleReplacematerialModal: false,
      // 替代物料选择 添加弹框
      visibleReplacematerialChooseModal: false,
      // 历史版本对比
      visibleCompareModal: false,

      ParentMaterialId: "",
      bomId: "",

      UpOrDown: "up",
      // 上方/下方添加物料
      visibleSubmateriaUpOrDownlModal: false,

      objHistory: {},

      paramsMaterialObj: {},
    };
  },
  created() {
    // 获取BOM 目录
    this.getMenus("");
  },
  methods: {
    getMenus(filter) {
      let params = {
        filter,
      };
      getcatagory(params).then((res) => {
        this.menus = res || [];

        // bom目录根据materialId判断是否新增或选中
        this.menuSelect();
      });
    },
    menuSelect() {
      let params = this.$route.params;

       params.materialId = params.id;

      console.log("menuSelect", params);
      if (params.masterId) {
        // 跳转过来 判断是否存在 masterId
        let isExist = false;
        this.menus.forEach((item) => {
          if (item.masterId == params.masterId) {
            isExist = true;
          }
        });

        if (isExist) {
          this.currentMenu.push(params.materialId);

          this.menuClick(params.materialId);
        } else {
          this.menus.unshift(params);

          this.currentMenu.push(params.materialId);

          // 处理字段名称 在table不兼容
          params.tlevel = 1;
          params.materialCode = params.fullCode;
          params.catagoryName = params.name;
          params.materialName = params.name;
          params.version = Number(params.version);
          params.materialClass = params.classificationName;
          params.selectDisabled = true;
          params.isFromMaterial = true;

          this.paramsMaterialObj = params;
          // 获取当前数据所有版本号 下拉列表
          this.getVersions(params);
        } 
      } else {
        // 不是跳转过来，首次进来默认选中第一个
        let materialId = this.menus[0]&&this.menus[0].materialId || '';
        if(materialId) {
          this.currentMenu.push(materialId);
          this.menuClick(materialId);
        }
      }
    },
    getVersions(params) {
      let obj = {
        classificationID: params.classificationID,
        fullCode: params.fullCode,
      };
      getVersionDownListDown(obj).then((res) => {
        console.log("getversions", res);
        params.versions = res;
        res.forEach((item) => {
          item.value = item.version;
          item.version = item.name;
        });
        this.dataSource = [params];
      });
    },
    menuClick(materialId, isFromMaterial) {
      if (isFromMaterial) {
        this.dataSource = [this.paramsMaterialObj];
      } else {
        this.getListTable(materialId);
      }
    },
    getListTable(materialId) {
      this.expandedRowKeys = [];

      let params = {
        ParentId: materialId,
      };

      getList(params).then((res) => {
        console.log("getList", res);
        if(res[0]) {
          res[0].tlevel = 1;
          res[0].childBom&&res[0].childBom.forEach((item) => {
            item.tlevel = 2;
          });
        }        

        this.dataSource = res;
        this.expandedRowKeys.push(res[0].id);
      });
    },
    getChildren(materialId, record) {
      let params = {
        ParentId: materialId,
      };

      getList(params).then((res) => {
        for(var item of res[0]['childBom']){
            item.quantity=record.quantity*item.quantity
        }
        this.getCurrentPart(record.id, this.dataSource, res);
      });
    },
    // 通过id找到对应的对象，并返回对象里面的children
    getCurrentPart(id, items, res) {
      for (var i in items) {
        let item = items[i];
        if (item.id == id) {
          item.childBom = res[0]&&res[0].childBom;
          break;
        } else if (item.childBom) {
          this.getCurrentPart(id, item.childBom, res);
        }
      }
    },
    onExpand(expanded, record) {
      console.log(expanded, record);
      if (expanded) {
        if (record.hasChild && record.childBom.length == 0) {
          this.getChildren(record.materialId, record);
        }
      }
    },
    onSearch(value) {
      console.log(value);
      this.getMenus(value);
    },
    // 切换版本下拉框，获取对应子物料
    onChangeSel(record, value) {
      console.log('changeSel', record, value);

      if(record.tlevel == 1) {
        this.getListTable(value.materialItemId)
      } else if(record.tlevel == 2) {
        this.getChildren(value.materialItemId, record);
      }
    },
    // 编辑单元格
    onCellChange(record, dataIndex, value) {
      let id = record.id
      console.log("onCellChange", id, dataIndex, value);
      if (dataIndex == "quantity") {
        let params = {
          BomId: id,
          IsReplace: false,
          Quantity: value,
        };

        update(params).then((res) => {
          console.log("update", res);
          if(res) {
            this.refreshList();
          }
        });
      } else if (dataIndex == "version") {
        if(record.tlevel == 2) {
          let params = {
            BomId: id,
            Version: value,
          };

          swichVersion(params).then((res) => {
            console.log("swichVersion", res);
            if (res && res.id) {
              this.refreshList();
            }
          });
        }        
      }
    },
    // 操作
    onOperClick(record, key) {
      console.log("key", key, record);
      if (key == 1) {
        // 添加子物料
        this.visibleSubmaterialModal = true;

        this.ParentMaterialId = record.materialId;
      } else if (key == 2) {
        // 上方添加物料
        this.UpOrDown = "up";
        this.bomId = record.id;

        this.visibleSubmateriaUpOrDownlModal = true;
      } else if (key == 3) {
        // 下方添加物料
        this.UpOrDown = "down";
        this.bomId = record.id;

        this.visibleSubmateriaUpOrDownlModal = true;
      } else if (key == 4) {
        // 上移
        this.Move(record, "up");
      } else if (key == 5) {
        // 下移
        this.Move(record, "down");
      } else if (key == 6) {
        // 编辑
        let id = record.materialId;
        this.$router.push({ path: `/MaterialManagement/edit/${id}` });
      } else if (key == 7) {
        // 删除
        let _this = this;
        let id = record.id;
        let params = {
          isReplace: false,
        };

        this.$confirm({
          title: "确认删除" + record.materialName + "?",
          onOk() {
            deleteBOM(id, params).then((res) => {
              console.log("deleteBom", res);
              _this.$message.success("删除成功");

              if (record.tlevel == 1) {
                _this.getMenus();
                _this.dataSource = [];
              } else {
                _this.refreshList();
              }
            });
          },
        });
      } else if (key == 8) {
        // 历史版本
        this.visibleCompareModal = true;

        this.objHistory = record;
      }
    },
    // 添加子物料
    AddSubmaterial() {
      let arrAdd = this.$refs.refSubmaterial.targetKeys;
      console.log("AddSubmaterial", arrAdd);

      let params = {
        MaterialIds: arrAdd,
        ParentMaterialId: this.ParentMaterialId,
      };

      if (arrAdd.length) {
        create(params).then((res) => {
          if (res) {
            this.visibleSubmaterialModal = false;

            this.refreshList();
          }
        });
      } else {
        this.$message.warning("没有选择物料");
      }
    },
    // 上方/下方添加物料
    AddSubmaterialUpOrDown() {
      let arrAddUpOrDown = this.$refs.refSubmaterialUpOrDown.targetKeys;
      console.log("AddSubmaterialUpOrDown", arrAddUpOrDown);

      let params = {
        id: this.bomId,
        MaterialIds: arrAddUpOrDown,
        TopOrDown: this.UpOrDown == "up" ? true : false,
      };

      if (arrAddUpOrDown.length) {
        insertbom(params).then((res) => {
          console.log("insertbom", res);
          if (res) {
            this.visibleSubmateriaUpOrDownlModal = false;

            this.refreshList();
          }
        });
      } else {
        this.$message.warning("没有选择物料");
      }
    },
    // 上移/下移
    Move(record, dir) {
      console.log("Move", record, dir);
      let params = {
        id: record.id,
        TopDown: dir == "up" ? true : false,
      };

      movebom(params).then((res) => {
        console.log("movebom", res);
        if (res) {
          this.refreshList();
        }
      });
    },
    // 替代料弹窗
    replacematerialClick(record) {
      console.log("replacematerialClick", record);
      this.visibleReplacematerialModal = true;

      this.bomId = record.id;
    },
    // 选择替代料弹窗
    AddReplaceOpen() {
      console.log("AddReplaceOpen");

      this.visibleReplacematerialChooseModal = true;
    },
    // 确认替换 选择添加
    AddReplacematerial() {
      let arrReplaceAdd = this.$refs.refReplaceSubmaterial.targetKeys;
      console.log("AddReplacematerial", arrReplaceAdd);

      let params = {
        BomId: this.bomId,
        MaterialIds: arrReplaceAdd,
        quantity: 1,
      };

      if (arrReplaceAdd.length) {
        addReplaceBom(params).then((res) => {
          if (res) {
            this.visibleReplacematerialChooseModal = false;

            // 更新替代物料弹窗
            this.$refs.refReplaceMaterial.getData();

            this.refreshList();
          }
        });
      } else {
        this.$message.warning("没有选择物料");
      }
    },
    refreshList() {
      // 更新列表
      let ParentMaterialId = this.dataSource[0].materialId;

      this.getListTable(ParentMaterialId);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 10px 20px;
}
.left {
  background: #fff;
  padding: 20px 30px;
  height: calc(100vh - 90px);
  overflow: auto;
}

.con {
  background: #fff;
  padding: 20px 30px;
  height: calc(100vh - 90px);
  overflow: auto;
}
</style>
